<template>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Clients List
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            
          </span>
        </h3>
        <div class="card-toolbar">
          <button class="btn btn-success font-weight-bolder font-size-sm" @click="MergeObjects">
            <span class="svg-icon svg-icon-md svg-icon-white">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
              <inline-svg src="media/svg/icons/Files/Download.svg" />
              <!--end::Svg Icon--> </span
            >Refresh</button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body py-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr class="text-left">
                <th class="pl-0" style="width: 20px">
                  <label class="checkbox checkbox-lg checkbox-single">
                    <input
                      type="checkbox"
                      @input="checkAll"
                    />
                    <span></span>
                  </label>
                </th>
                <th class="pr-0" style="width: 200px">company</th>
                <th style="min-width: 150px">time</th>
                <th class="pr-0 text-right" style="min-width: 100px">action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in clients">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <label class="checkbox checkbox-lg checkbox-single">
                      <input type="checkbox" v-model="item.done" />
                      <span></span>
                    </label>
                  </td>
                  <td class="pl-0">
                    <a
                     
                      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      :class="{completed: item.done}"
                      ><font :color="item.color">{{ item.name }}</font></a
                    >
                    <span
                      class="text-muted font-weight-bold text-muted d-block"
                      >{{ item.email }}</span
                    >
                  </td>
                  <td>
                    <div class="d-flex flex-column w-100 mr-2">
                      <div
                        class="d-flex align-items-center justify-content-between mb-2"
                      >
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ item.tracked_seconds }}</span
                        >
                      
                        
                      </div>
                      
                    </div>
                  </td>
                  <td class="pr-0 text-right">
                    <a
                     @click="openPages(item)"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                     
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg
                          src="media/svg/icons/Files/File.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                    <a
                      :href="mail(item)"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg src="media/svg/icons/Communication/Mail.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
  
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "widget-2",
    data() {
      return {
        TogglProjects: [],
        TogglProjectsSummary: [],
        clients: [],
        NomClients: [],
        detailsClients: [
          {
            name : "VAN DER PLAS",
            email : "factuur@vdplas.com",
            urlrapport : "https://track.toggl.com/shared-report/bd29f3b42f87527f5fc069461bd6283c",
            lang : "EN",
            urlreportdetail : "https://track.toggl.com/shared-report/7546dcb7448b6fbfe96f5be3d75b85ca"
            
          },
          {
            name : "DEXX Groupe",
            email : "Anne Fege <a.fege@dexx.fr>; Fabrice Cheze-Froment <fabrice@dexx.fr>",
            urlrapport : "https://track.toggl.com/shared-report/8a30045993cbff0609c504cf9d1de7c0",
            lang : "FR",
            urlreportdetail : "https://track.toggl.com/shared-report/8d93c52e58627d9340e538a7d28340b5"
          },
          {
            name : "FTG",
            email : "invoice@Floraltradegroup.com",
            urlrapport : "https://track.toggl.com/shared-report/a9322608fc78f7edd55ee46d297592e7",
            lang : "EN",
            urlreportdetail : "https://track.toggl.com/shared-report/6255e30428489267b27f89d5bf6fd231"

          },
          {
            name : "Flora+",
            email : "pej@floraplus.eu",
            urlrapport : "https://track.toggl.com/shared-report/7c2b2814ba52c98cb0ab6b838299ae9d",
            lang : "EN",
            urlreportdetail : ""

          },
          {
            name : "Dundonalds",
            email : "Andrew McCully <andrew@dundonaldnurseries.co.uk>; accounts@dundonaldnurseries.co.uk",
            urlrapport : "https://track.toggl.com/shared-report/1bec8536d01a85f3310a7dfb65b1d3a4",
            lang : "EN",
            urlreportdetail : ""

          },
          {
            name : "Green & Bloom",
            email : "Katherine Diplock <k.diplock@greenandbloom.co.uk>; Freddie Heathcote <f.heathcote@greenandbloom.co.uk>",
            urlrapport : "https://track.toggl.com/shared-report/83f827a2dce287f35683757ba5fd65b1",
            lang : "EN",
            urlreportdetail : ""

            
          },
          {
            name : "FLORAZUR",
            email : "eurlflorazur@gmail.com",
            urlrapport : "https://track.toggl.com/shared-report/67d5a8dde7bfb5bae19a9e0c4d9dc74a",
            lang : "FR",
            urlreportdetail : "https://track.toggl.com/shared-report/9de74c31e15d26c1d80d5b42174db6e0"

          },
          {
            name : "AGRESTA Immo",
            email : "laurent@agrestaimmo.fr",
            urlrapport : "pego : https://track.toggl.com/shared-report/a6b127a4e0cbbc3733ad4771252d191b\nCannes : https://track.toggl.com/shared-report/d8b4f7ef22de9ec930a62316218ecd85\nTignet : https://track.toggl.com/shared-report/bb711a2eaddda0b7169ce0859b09fe70",
            lang : "FR",
            urlreportdetail : ""

          },
          {
            name : "Max Line",
            email : "maxim.vangammeren@max-line.nl",
            urlrapport : "",
            lang : "EN",
            urlreportdetail : ""

          },
          {
            name : "SICA",
            email : "zeeuwrob@hotmail.com",
            urlrapport : "",
            lang : "FR",
            urlreportdetail : ""

          },
          {
            name : "Ecole EIB",
            email : "eibpegomas@gmail.com",
            urlrapport : "https://track.toggl.com/shared-report/4cd30fcf92ebf2713842ceb1c410c319",
            lang : "FR",
            urlreportdetail : ""
          },
          {
            name : "BELGRANO",
            email : "belgrano0179@orange.fr;belgrano@orange.fr",
            urlrapport : "https://track.toggl.com/shared-report/077e14fb9aab96ef4f82d2b0fe715175",
            lang : "FR",
            urlreportdetail : ""
          },
          {
            name : "AVO",
            email : "laurent.verrecchia@avogreen.fr;administration@avogreen.fr;'Jérôme Olivier' <Jerome.Olivier@avogreen.fr>",
            urlrapport : "https://track.toggl.com/shared-report/e1d926af7e90156045ad9bbe6f62a64c",
            lang : "FR",
            urlreportdetail : ""
          },
          {
            name : "Floret",
            email : "j.filthuth@floret.dk",
            urlrapport : "https://track.toggl.com/shared-report/689eaca829bcd4147fb269eddb65a0dc",
            lang : "EN",
            urlreportdetail : "https://track.toggl.com/shared-report/cde4961735180f8804b7ed22213448f6"
          },
          {
            name : "Greenflor",
            email : "facturen@greenflor.nl",
            urlrapport : "https://track.toggl.com/shared-report/254bcddf83723b236d173c63bb4f0975",
            lang : "EN",
            urlreportdetail : ""
          },
  
        ],
        
        checked: false
      };
    },
    mounted() {
      this.GetTogglProjects();
      this.GetTogglProjectsSummary();
      this.getDataReport();
    },
    components: {},
    methods: {
      MergeObjects(){
        
        this.clients = this.TogglProjectsSummary;
        this.NomClients = this.TogglProjects;

        this.clients = this.clients.map((item) => {
            const match = this.NomClients.find((item2) => item2.id === item.project_id);
            return { 
              user_id: item.user_id,
              project_id: item.project_id,
              tracked_seconds: item.tracked_seconds,
              name: match ? match.name : '',
              color: match ? match.color : '',
              };
            });
  
  
          var clientsAllDexx = this.clients.filter(function (el) {
          return el.name.startsWith("DEXX");
        });
        var totaltimeDEXX = 0;
        clientsAllDexx.forEach(item => {
          totaltimeDEXX +=item.tracked_seconds;
        });
        
        this.clients = this.clients.filter(function (el) {
          return  !el.name.startsWith("DEXX") || el.name == "DEXX Groupe";
        });
        this.clients.forEach(item => {
         if(item.name == "DEXX Groupe"){
          item.tracked_seconds = totaltimeDEXX;
         }
        });
            this.clients = this.clients.map((item) => {
          const match = this.detailsClients.find((item2) => item2.name === item.name);
          var time = secInHHMMSS(item.tracked_seconds);
          return { 
            user_id: item.user_id,
            project_id: item.project_id,
            tracked_seconds: time,
            name: item.name,
            color: item.color,
            email: match ? match.email : null,
            urlrapport: match ? match.urlrapport : null,
            urlreportdetail: match ? match.urlreportdetail : null,
            lang: match ? match.lang : null,
            done: false
            };
          });
                    
        // const Today = new Date();
        // const lastDay = new Date();
        // lastDay.setDate(0);
        // var nbJours = lastDay.getDate();
        // if(Today.getMonth() == 0){
        //   nbJours = 31;
        // }
        // function Month(){
        
        //   if(Today.getMonth() == 0){
        //     return 12;
        //   }
        //   if(Today.getMonth() < 10){
        //     return `0${Today.getMonth()}`;
        //   }
        //   else{
        //     return Today.getMonth();
        //   }
        // }
        // function Year(){
        //   if(Today.getMonth() == 0){
        //     return Today.getFullYear() - 1;
        //   }
        //   else{
        //     return Today.getFullYear();
        //   }
        // } 
        
        function secInHHMMSS(time){
          var hours   = Math.floor(time / 3600);
          var minutes = Math.floor((time - (hours * 3600)) / 60);
          var seconds = time - (hours * 3600) - (minutes * 60);
          if (hours   < 10) {hours   = `0${hours}`;}
          if (minutes < 10) {minutes = `0${minutes}`;}
          if (seconds < 10) {seconds = `0${seconds}`;}
          if(isNaN(hours) || isNaN(minutes) || isNaN(seconds)){
            return time;
          }
          else{
            return `${hours}:${minutes}:${seconds}`;
          }   
      }
    
        // // const dateDebut = `${Year()}-${Month()}-01`;
        // // const dateFin = `${Year()}-${Month()}-${nbJours}`;
        // // const data = {end_date: dateFin ,start_date: dateDebut}
        // // fetch("https://api.track.toggl.com/reports/api/v3/workspace/1011836/projects/summary",
        // // {
        // //   method:  'POST',
        // //   headers: {
        // //     "Content-Type": "application/json",
        // //     "Authorization": `Basic ` + btoa("180fc617a7a766dbae372eb11f9c0f79" + ":api_token"),
            
        // //   },
        // //   body: JSON.stringify(data)
          
        // // })
        
        // axios.post("/home/GetTogglProjectsSummary")
        // // .then(response => response.json())
        // .then(response => response.data)
        // .then((json) => {
        //   this.clients = json;
        //   // console.log(this.clients);
        // //   fetch("https://api.track.toggl.com/api/v9/workspaces/1011836/projects",
        // // {
        // //   method:  'GET',
        // //   headers: {
        // //     "Content-Type": "application/json",
        // //     "Authorization": `Basic ` + btoa("180fc617a7a766dbae372eb11f9c0f79" + ":api_token"),
            
        // //   },
  
        // // })
        // axios.get("/home/GetTogglProjects")
        // // .then(response => response.json())
        // .then(response => response.data)
        // .then((json)=>{
        //   this.NomClients = json;
        //   // console.log(this.NomClients);
        //   this.clients = this.clients.map((item) => {
        //     const match = this.NomClients.find((item2) => item2.id === item.project_id);
        //     return { 
        //       user_id: item.user_id,
        //       project_id: item.project_id,
        //       tracked_seconds: item.tracked_seconds,
        //       name: match ? match.name : '',
        //       color: match ? match.color : '',
        //       };
        //     });
  
  
        //   var clientsAllDexx = this.clients.filter(function (el) {
        //   return el.name.startsWith("DEXX");
        // });
        // var totaltimeDEXX = 0;
        // clientsAllDexx.forEach(item => {
        //   totaltimeDEXX +=item.tracked_seconds;
        // });
        
        // this.clients = this.clients.filter(function (el) {
        //   return  !el.name.startsWith("DEXX") || el.name == "DEXX Groupe";
        // });
        // this.clients.forEach(item => {
        //  if(item.name == "DEXX Groupe"){
        //   item.tracked_seconds = totaltimeDEXX;
        //  }
        // });
        //     this.clients = this.clients.map((item) => {
        //   const match = this.detailsClients.find((item2) => item2.name === item.name);
        //   var time = secInHHMMSS(item.tracked_seconds);
        //   return { 
        //     user_id: item.user_id,
        //     project_id: item.project_id,
        //     tracked_seconds: time,
        //     name: item.name,
        //     color: item.color,
        //     email: match ? match.email : null,
        //     urlrapport: match ? match.urlrapport : null,
        //     urlreportdetail: match ? match.urlreportdetail : null,
        //     lang: match ? match.lang : null,
        //     done: false
        //     };
        //   });
          
         
  
        // })
        // .catch((error) => {
        //   return error;
        //   // console.error(error);
        // });
        // })
        // .catch((error) => {
        //   return error;
        //   // console.error(error);
        // });
  
       
  
      },
      GetTogglProjects(){
        axios
          .get("/home/GetTogglProjects")
          .then((response) => {
            this.TogglProjects = response.data;
            this.loading = false;
            // console.log(response.data);
          })
          .catch((error) => {
            this.error.response = "Error msg";
            // console.log(error.response)
            this.loading = false;
            throw error;
          });
      },
      GetTogglProjectsSummary(){
        axios
          .post("/home/GetTogglProjectsSummary")
          .then((response) => {
            this.TogglProjectsSummary = response.data;
            this.loading = false;
            // console.log(response.data);
          })
          .catch((error) => {
            this.error.response = "Error msg";
            // console.log(error.response)
            this.loading = false;
            throw error;
          });
      },
      openPages(item){

       if (item.urlrapport.length>0){
        window.open(item.urlrapport, '_blank');
       }
       if(item.urlreportdetail.length>0){
   
        window.open(item.urlreportdetail, '_blank');
       }
      },

         
  
      mail(item) {
        var subject = "";
        var body = "";
        var date = new Date();
        var lastMonth = new Date(date.getFullYear(), date.getMonth() - 1);
        var month;
        if(item.lang == "FR"){
           month = lastMonth.toLocaleString('fr-FR', { month: 'long' });
           if(month == "aout" || month == "avril" || month == "octobre" ){
            month = "d'" + month;
           }else{
            month = "de " + month;
           }
           subject = "Facture " + month;
           body = "Bonjour,\n\nVeuillez trouver ci-joint la facture du mois "+month+"\nEt ci-dessous le rapport interactif\nRécap : "+item.urlrapport+"\nDétaillé : "+item.urlreportdetail+"\n\nZEEUW Robert";
          return `mailto:${item.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        }else{
           month = lastMonth.toLocaleString('en-GB', { month: 'long' });
           subject = "Invoice " + month;
           body = "Hello,\n\nFind attached the invoice for "+month+"\nAnd below the interactive report\nOverview : "+item.urlrapport+"\nDetailed : "+item.urlreportdetail+"\n\nZEEUW Robert";
          return `mailto:${item.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        }
      },
  
      async getDataReport(){
        
      },
  
      checkAll() {
        this.clients.forEach(item => {
          item.done = event.target.checked;
        });
      },
  
      setCheck(checked) {
        this.checked = checked;
      }
    }
  };
  </script>
  
  <style>
  .completed {
    text-decoration: line-through;
    
  }
  </style>